import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './afk-guide.scss';

const AFKGuidesArti: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page afk-journey afk-guide'}
      game="afk"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/afk-journey/">AFK Journey</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/afk-journey/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Artifacts - Season 2</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/afk/categories/category_arti_s.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>AFK Journey Artifacts guide</h1>
          <h2>List of Artifacts available in the Season 2 of AFK Journey.</h2>
          <p>
            Last updated: <strong>13/09/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Artifacts - Season 2" />
        <p>
          Season 2 introduces 10 new Artifacts that are more specialized than
          the base game ones. Below you will find a list of them all:
        </p>
        <div className="artifacts">
          <div className="column">
            <div className="artifact-header">
              <StaticImage
                src="../../../images/afk/season/arti_arcane.webp"
                alt="Guides"
              />
            </div>
            <h5>Arcane Spell</h5>
            <div className="artifact-description">
              <p className="sub">Description:</p>
              <p>
                Whenever your allies' magic damage dealt reaches 750% of your
                initial Team ATK, fire 3 magic projectiles, with each targetting
                the frontmost enemy and prioritizing enemies who haven't already
                been hit. Each projectile deals magic damage equal to 40% of
                Team ATK and reduces the target's Magic DEF by 40% for 5s.
                Unstackable.
              </p>
              <ul>
                <li>
                  <strong>At +5:</strong> Increases the damage of this skill to
                  45% of Team ATK.
                </li>
                <li>
                  <strong>At +10:</strong> Increases the damage of this skill to
                  50% of Team ATK.
                </li>
                <li>
                  <strong>At +15:</strong> Increases the damage of this skill to
                  55% of Team ATK.
                </li>
                <li>
                  <strong>At +20:</strong> Increases the Magic DEF reduction to
                  50%
                </li>
              </ul>
            </div>
            <div className="artifact-stats">
              <p className="sub">Stats (at level 10):</p>
              <ul>
                <li>
                  ATK <strong>+5.4%</strong>.{' '}
                </li>
                <li>
                  Phys / Magic DEF <strong>+10.2%</strong>.
                </li>
                <li>
                  DEF Penetration <strong>+3.6</strong>.
                </li>
              </ul>
            </div>
            <div className="artifact-review">
              <p className="sub">Usage:</p>
              <p>Coming soon!</p>
            </div>
            <p className="unlock">
              Unlocks: <strong>Coming soon!</strong>
            </p>
          </div>
          <div className="column">
            <div className="artifact-header">
              <StaticImage
                src="../../../images/afk/season/arti_binding.webp"
                alt="Guides"
              />
            </div>
            <h5>Binding Spell</h5>
            <div className="artifact-description">
              <p className="sub">Description:</p>
              <p>
                3s into a battle, levitates the rearmost enemy every 16s,
                imprisons them for 1s, then throws them at another rearmost
                enemy. This collision deals physical damage equal to 30% of Team
                ATK to both enemies and stuns them for 1s.
              </p>
              <ul>
                <li>
                  <strong>At +5:</strong> Reduces this skill cooldown to 15s.
                </li>
                <li>
                  <strong>At +10:</strong> Reduces this skill cooldown to 14s.
                </li>
                <li>
                  <strong>At +15:</strong> Reduces this skill cooldown to 13s.
                </li>
                <li>
                  <strong>At +20:</strong> Extends the stun duration from the
                  collision between the 2 enemies to 1.5s.
                </li>
              </ul>
            </div>
            <div className="artifact-stats">
              <p className="sub">Stats (at level 10):</p>
              <ul>
                <li>
                  Crit <strong>+7.2</strong>.{' '}
                </li>
                <li>
                  Phys / Magic DEF <strong>+16.8%</strong>.
                </li>
                <li>
                  HP <strong>+5.4%</strong>.
                </li>
              </ul>
            </div>
            <div className="artifact-review">
              <p className="sub">Usage:</p>
              <p>Coming soon!</p>
            </div>
            <p className="unlock">
              Unlocks: <strong>Coming soon!</strong>
            </p>
          </div>
          <div className="column">
            <div className="artifact-header">
              <StaticImage
                src="../../../images/afk/season/arti_frontline.webp"
                alt="Guides"
              />
            </div>
            <h5>Frontline Spell</h5>
            <div className="artifact-description">
              <p className="sub">Description:</p>
              <p>
                Whenever an allied hero first enters the enemy side of
                battlefield, grant that hero a permanent shield equal to 60% of
                Team ATK. When 3 different allied heroes have entered the enemy
                side of battlefield, place a banner of inspiration there. The
                banner increases the damage of all allies by 20% and reduces
                their damage taken by 20% while they are on the enemy side.
              </p>
              <ul>
                <li>
                  <strong>At +5:</strong> Increases the damage buff from the
                  banner of inspiration to 24%.
                </li>
                <li>
                  <strong>At +10:</strong> Increases the damage buff from the
                  banner of inspiration to 28%.
                </li>
                <li>
                  <strong>At +15:</strong> Increases the damage buff from the
                  banner of inspiration to 32%.
                </li>
                <li>
                  <strong>At +20:</strong> Increases the Life Drain of allies on
                  the enemy side by an additional 15.
                </li>
              </ul>
            </div>
            <div className="artifact-stats">
              <p className="sub">Stats (at level 10):</p>
              <ul>
                <li>
                  Haste <strong>+4.8</strong>.{' '}
                </li>
                <li>
                  Phys / Magic DEF <strong>+8.4%</strong>.
                </li>
                <li>
                  Life Drain <strong>+4.8</strong>.
                </li>
              </ul>
            </div>
            <div className="artifact-review">
              <p className="sub">Usage:</p>
              <p>Coming soon!</p>
            </div>
            <p className="unlock">
              Unlocks: <strong>Coming soon!</strong>
            </p>
          </div>
          <div className="column">
            <div className="artifact-header">
              <StaticImage
                src="../../../images/afk/season/arti_great.webp"
                alt="Guides"
              />
            </div>
            <h5>Greatsword Spell</h5>
            <div className="artifact-description">
              <p className="sub">Description:</p>
              <p>
                Whenever your allies' physical damage dealt reaches 750% of your
                initial Team ATK, summon a giant sword from the sky to strike
                the area with the most enemies. This deals physical damage equal
                to 40% of Team ATK to all enemies within 1 tiles and reduces
                their Phys DEF by 40% for 5s.
              </p>
              <ul>
                <li>
                  <strong>At +5:</strong> Increases the damage of this skill to
                  45% of Team ATK.
                </li>
                <li>
                  <strong>At +10:</strong> Increases the damage of this skill to
                  50% of Team ATK.
                </li>
                <li>
                  <strong>At +15:</strong> Increases the damage of this skill to
                  55% of Team ATK.
                </li>
                <li>
                  <strong>At +20:</strong> Increases the Phys DEF reduction to
                  50%
                </li>
              </ul>
            </div>
            <div className="artifact-stats">
              <p className="sub">Stats (at level 10):</p>
              <ul>
                <li>
                  ATK SPD <strong>+4.8</strong>.{' '}
                </li>
                <li>
                  ATK <strong>+6%</strong>.
                </li>
                <li>
                  Life Drain <strong>+3</strong>.
                </li>
              </ul>
            </div>
            <div className="artifact-review">
              <p className="sub">Usage:</p>
              <p>Coming soon!</p>
            </div>
            <p className="unlock">
              Unlocks: <strong>Coming soon!</strong>
            </p>
          </div>
          <div className="column">
            <div className="artifact-header">
              <StaticImage
                src="../../../images/afk/season/arti_resonating.webp"
                alt="Guides"
              />
            </div>
            <h5>Resonating Spell</h5>
            <div className="artifact-description">
              <p className="sub">Description:</p>
              <p>
                When a battle starts, designates the rearmost ally as the
                "Resonator". Whenever any other ally casts their Ultimate, the
                Resonator recovers 60 Energy. The Resonator can only recover up
                to 1500 Energy per battle. When the Resonator casts their
                Ultimate, they enter a blessed state for 6s, gaining immunity
                and a 35% increase to their ATK. This blessing cannot be
                stacked.
              </p>
              <ul>
                <li>
                  <strong>At +5:</strong> When any ally other than the Resonator
                  casts their Ultimate, the Resonator's Energy recovery is
                  increased to 65.
                </li>
                <li>
                  <strong>At +10:</strong> When any ally other than the
                  Resonator casts their Ultimate, the Resonator's Energy
                  recovery is increased to 70.
                </li>
                <li>
                  <strong>At +15:</strong> Increases the duration of blessing to
                  8s.
                </li>
                <li>
                  <strong>At +20:</strong> Each time the Resonator casts their
                  Ultimate, they will instantly recover 20% of their lost HP, up
                  to 50% of the Team ATK.
                </li>
              </ul>
            </div>
            <div className="artifact-stats">
              <p className="sub">Stats (at level 10):</p>
              <ul>
                <li>
                  Haste <strong>+3</strong>.{' '}
                </li>
                <li>
                  HP <strong>+6%</strong>.
                </li>
                <li>
                  ATK <strong>+4.8%</strong>.
                </li>
              </ul>
            </div>
            <div className="artifact-review">
              <p className="sub">Usage:</p>
              <p>Coming soon!</p>
            </div>
            <p className="unlock">
              Unlocks: <strong>Coming soon!</strong>
            </p>
          </div>
          <div className="column">
            <div className="artifact-header">
              <StaticImage
                src="../../../images/afk/season/arti_swift.webp"
                alt="Guides"
              />
            </div>
            <h5>Switftheal Spell</h5>
            <div className="artifact-description">
              <p className="sub">Description:</p>
              <p>
                8s into a battle, cast a healing wave that restores 35% of Team
                ATK HP to the weakest ally every 12s. The healing wave then
                bounces to the next weakest ally, healing them for a reduced
                amount. The healing wave can bounce up to 2 times, with each
                bounce reducing the healing by 25%.
              </p>
              <ul>
                <li>
                  <strong>At +5:</strong> Increases the initial healing amount
                  to 40% of Team ATK.
                </li>
                <li>
                  <strong>At +10:</strong> Increases the initial healing amount
                  to 45% of Team ATK.
                </li>
                <li>
                  <strong>At +15:</strong> Increases the initial healing amount
                  to 50% of Team ATK.
                </li>
                <li>
                  <strong>At +20:</strong> Increases the healing wave's maximum
                  bounces to 3 and reduces the healing reduction with each
                  bounce to 20%
                </li>
              </ul>
            </div>
            <div className="artifact-stats">
              <p className="sub">Stats (at level 10):</p>
              <ul>
                <li>
                  ATK <strong>+3%</strong>.{' '}
                </li>
                <li>
                  Phys / Magic DEF <strong>+13.2%</strong>.
                </li>
                <li>
                  Vitality <strong>+4.8</strong>.
                </li>
              </ul>
            </div>
            <div className="artifact-review">
              <p className="sub">Usage:</p>
              <p>Coming soon!</p>
            </div>
            <p className="unlock">
              Unlocks: <strong>Coming soon!</strong>
            </p>
          </div>
          <div className="column">
            <div className="artifact-header">
              <StaticImage
                src="../../../images/afk/season/arti_thunder.webp"
                alt="Guides"
              />
            </div>
            <h5>Thunderclap Spell</h5>
            <div className="artifact-description">
              <p className="sub">Description:</p>
              <p>
                Cast 1 of the 2 following spells alternately every 3s: Red
                Lightning: Deals physical damage equal to 30% of Team ATK to the
                healthiest enemy, plus extra damage equal to 2% of their current
                HP. Blue Lightning: Deals magic damage equal to 30% of Team ATK
                to the weakest enemy, plus extra damage equal to 2% of their
                lost HP. The extra damage cannot surpass 10% of Team ATK.
              </p>
              <ul>
                <li>
                  <strong>At +5:</strong> Increases the damage of this skill to
                  33% of Team ATK.
                </li>
                <li>
                  <strong>At +10:</strong> Increases the damage of this skill to
                  36% of Team ATK.
                </li>
                <li>
                  <strong>At +15:</strong> Increases the damage of this skill to
                  39% of Team ATK.
                </li>
                <li>
                  <strong>At +20:</strong> Whenever a non-summoned enemy is
                  defeated, grant all allies a shield equal to 20% of Team ATK
                  for 5s.
                </li>
              </ul>
            </div>
            <div className="artifact-stats">
              <p className="sub">Stats (at level 10):</p>
              <ul>
                <li>
                  HP <strong>+7.8%</strong>.{' '}
                </li>
                <li>
                  ATK <strong>+4.8%</strong>.
                </li>
                <li>
                  Execution <strong>+4.2</strong>.
                </li>
              </ul>
            </div>
            <div className="artifact-review">
              <p className="sub">Usage:</p>
              <p>Coming soon!</p>
            </div>
            <p className="unlock">
              Unlocks: <strong>Coming soon!</strong>
            </p>
          </div>
          <div className="column">
            <div className="artifact-header">
              <StaticImage
                src="../../../images/afk/season/arti_tide.webp"
                alt="Guides"
              />
            </div>
            <h5>Tidecall Spell</h5>
            <div className="artifact-description">
              <p className="sub">Description:</p>
              <p>
                After allied heroes have cast their Ultimate a total of 5 times,
                evoke the Tidal Strength to strike all enemies, dealing magic
                damage equal to 60% of Team ATK and inflicting "soaked" for 4s.
                "Soaked" enemies deal 30% less damage and will take 25% more
                damage.
              </p>
              <ul>
                <li>
                  <strong>At +5:</strong> Increases the damage of this skill to
                  65% of Team ATK.
                </li>
                <li>
                  <strong>At +10:</strong> Increases the damage of this skill to
                  70% of Team ATK.
                </li>
                <li>
                  <strong>At +15:</strong> Increases the damage of this skill to
                  75% of Team ATK.
                </li>
                <li>
                  <strong>At +20:</strong> Triggered after allies use 4
                  Ultimates.
                </li>
              </ul>
            </div>
            <div className="artifact-stats">
              <p className="sub">Stats (at level 10):</p>
              <ul>
                <li>
                  Haste <strong>+4.2</strong>.{' '}
                </li>
                <li>
                  CRIT <strong>+4.2</strong>.
                </li>
                <li>
                  HP <strong>+8.4%</strong>.
                </li>
              </ul>
            </div>
            <div className="artifact-review">
              <p className="sub">Usage:</p>
              <p>Coming soon!</p>
            </div>
            <p className="unlock">
              Unlocks: <strong>Coming soon!</strong>
            </p>
          </div>
          <div className="column">
            <div className="artifact-header">
              <StaticImage
                src="../../../images/afk/season/arti_valor.webp"
                alt="Guides"
              />
            </div>
            <h5>Valorshield Spell</h5>
            <div className="artifact-description">
              <p className="sub">Description:</p>
              <p>
                When a battle starts, grants a shield equal to 100% of the Team
                ATK to both the frontmost and the rearmost allies, lasting up to
                10s. While the shield is active, the shielded allies remain
                immune to control effects. Additionally, the frontmost ally
                gains 75% Phys DEF and Magic DEF, while the rearmost ally gains
                60 Haste.
              </p>
              <ul>
                <li>
                  <strong>At +5:</strong> Increases the shield value to 110% of
                  the Team ATK
                </li>
                <li>
                  <strong>At +10:</strong> Increases the shield value to 120% of
                  the Team ATK
                </li>
                <li>
                  <strong>At +15:</strong> Increases the shield value to 130% of
                  the Team ATK
                </li>
                <li>
                  <strong>At +20:</strong> Increases the frontmost ally's Phys
                  DEF and Magic DEF boost to 90%, the rearmost ally's Haste
                  boost to 75, and extends the shield duration to 12s.
                </li>
              </ul>
            </div>
            <div className="artifact-stats">
              <p className="sub">Stats (at level 10):</p>
              <ul>
                <li>
                  Phys / Magic DEF <strong>+14.4%</strong>.{' '}
                </li>
                <li>
                  ATK <strong>+4.2%</strong>.
                </li>
                <li>
                  Haste <strong>+3.6</strong>.
                </li>
              </ul>
            </div>
            <div className="artifact-review">
              <p className="sub">Usage:</p>
              <p>Coming soon!</p>
            </div>
            <p className="unlock">
              Unlocks: <strong>Coming soon!</strong>
            </p>
          </div>
          <div className="column">
            <div className="artifact-header">
              <StaticImage
                src="../../../images/afk/season/arti_verdant.webp"
                alt="Guides"
              />
            </div>
            <h5>Verdant Spell</h5>
            <div className="artifact-description">
              <p className="sub">Description:</p>
              <p>
                At the start of the battle and every subsequent 15 seconds, each
                hero and their summon will receive a corresponding buff based on
                their class, lasting for 5 seconds. For each different class
                deployed, the duration of the buff is extended by an additional
                1 seconds, up to a maximum of 8 seconds. Warrior: Gains 20
                points of Life Drain; Tank: Gains 20% DMG Reduction; Rogue:
                Gains 20 points of Penetration; Support: Gains 20 points of
                Haste; Marksman: Increases ATK SPD by 35 points; Mage: Energy
                regeneration speed is increased by 20%.
              </p>
              <ul>
                <li>
                  <strong>At +5:</strong> Increases the Life Drain from the
                  Warrior buff to 30 and the DMG Reduction from the Tank buff to
                  25%
                </li>
                <li>
                  <strong>At +10:</strong> Increases the Penetration from the
                  Rogue buff to 30 and the Haste from the Support buff to 30.
                </li>
                <li>
                  <strong>At +15:</strong> Increases the ATK SPD from the
                  Marksman buff to 50 and the Energy Recovery from the Mage buff
                  to 30%.
                </li>
                <li>
                  <strong>At +20:</strong> When applying buffs to heroes of at
                  least 3 different classes, also restore 40 Energy to the
                  heroes receiving the buffs.
                </li>
              </ul>
            </div>
            <div className="artifact-stats">
              <p className="sub">Stats (at level 10):</p>
              <ul>
                <li>
                  Phys / Magic DEF <strong>+9.6%</strong>.{' '}
                </li>
                <li>
                  ATK <strong>+5.4%</strong>.
                </li>
                <li>
                  HP <strong>+5.4%</strong>.
                </li>
              </ul>
            </div>
            <div className="artifact-review">
              <p className="sub">Usage:</p>
              <p>Coming soon!</p>
            </div>
            <p className="unlock">
              Unlocks: <strong>Coming soon!</strong>
            </p>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AFKGuidesArti;

export const Head: React.FC = () => (
  <Seo
    title="Artifacts - Season 2 | AFK Journey | Prydwen Institute"
    description="List of Artifacts available in the Season 2 of AFK Journey."
    game="afk"
  />
);
